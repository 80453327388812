import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PriceList from "../components/priceList";

const Cenik = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "pricing" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              validFrom
              priceWithDph
              pricing {
                title
                capacity
                volume
                hourRate
                pricePerKm
                accesories
              }
            }
          }
        }
      }
    }
  `);
  const { pricing, priceWithDph, validFrom } =
    allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <Layout path="cenik">
      <SEO title="Ceník" />
      <div className="Price">
        <h1 className="Price__title">Cenová nabídka strojů</h1>
        <span className="Price__subtitle">
          Ceníky jsou platné od {validFrom}
        </span>
        <PriceList dph={priceWithDph} pricing={pricing} />
      </div>
    </Layout>
  );
};

export default Cenik;
