import React from "react";
import { decode } from "../js/entities";

const PriceList = ({ dph, pricing }) => {
  return (
    <table className="Price__table" id="cenik">
      <thead>
        <tr>
          <th className="Price__row--vehicle">Stroj</th>
          <th className="Price__row--center">Nosnost</th>
          <th className="Price__row--center">Objem korby</th>
          <th className="">Hodinová sazba*</th>
          <th className="">Cena za kilometr*</th>
          <th className="">Příslušenství</th>
        </tr>
      </thead>
      <tbody className="Price__tbody">
        {pricing.map((price) => {
          return (
            <tr key={price.title}>
              <td>{decode(price.title)}</td>
              <td>{price.capacity}</td>
              <td>{price.volume}</td>
              <td>{price.hourRate}</td>
              <td>{price.pricePerKm}</td>
              <td>{price.accesories}</td>
            </tr>
          );
        })}
      </tbody>
      <span className="Price__tableInfo">
        {`Ceny jsou ${dph ? "s" : "bez"} DPH*`}
      </span>
    </table>
  );
};

export default PriceList;
